import Web3 from "web3";

const getWeb3 = () =>
  new Promise(async(resolve, reject) => {
    // I removed the on window load event because it was happening before the event handler could be put in place.
    // Wait for loading completion to avoid race conditions with web3 injection timing.
    // window.addEventListener("load", async () => {
      // Modern dapp browsers...
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const web3 = new Web3(window.ethereum);
          resolve(web3);
        } catch (error) {
          reject(error);
        }
      }
      // Legacy dapp browsers...
      else if (window.web3) {
        // Use Mist / MetaMask provider.
        const web3 = window.web3;
        console.log("Injected web3 detected.");
        resolve(web3);
      }
      else {
        reject('Unable to connect to blockchain. No window.ethereum nor window.web3 element was available.');
      }
      // I removed the code below because it isn't clear that it is actually desirable.
      // // Fallback to localhost; use dev console port by default...
      // else {
      //   const provider = new Web3.providers.HttpProvider(
      //     "http://127.0.0.1:8545"
      //   );
      //   const web3 = new Web3(provider);
      //   console.log("No web3 instance injected, using Local web3.");
      //   resolve(web3);
      // }
    // });
  });

export default getWeb3;
